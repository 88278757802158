<template>
    <div >
    <!-- <NoButtonModal :open='open' @close="$emit('close')"> -->
        <GrpApptWarning :open='grp_warning' :type='selectedEventType' @closeModal='grp_warning = false' @createGrpAppt='closeGrpWarning' />
        <form id='form-element' @submit.prevent='create'>
            <!-- <div> -->
                <!-- <div>
                    <Title :eventType='selectedEventType' @setTitle='setTitle' :modalOpen='open' />
                    <div class="flex center bottom-20" v-if="selectedEventType !== 'Other'">
                        <input
                            class="right-10 color"
                            id='isRequired'
                            type='checkbox'
                            v-model='apptIsRequired'
                        >
                        <label for='isRequired' class="margin-0 left-10">
                            Count toward required sessions
                        </label>
                    </div>

                    <DateTime
                        :date='date'
                        :open='open'
                        @dateTimeSelected='setDateTime'
                        @rRule='changeRuleData'
                        :isRecurring='recurringAppt'
                    />

                    <label class="bottom-10" v-if="selectedEventType !== 'Other'">
                        Recurring Appointment
                        <Toggle
                            id='isMonorToggle'
                            class='toggle-switch'
                            v-model='recurringAppt'
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                        />
                    </label>

                    <Location
                        :rooms='rooms'
                        :modalOpen='open'
                        :typeofAppt='selectedEventType'
                        @selectedPlaceOfService='setPlaceOfService'
                        @setApptType='setApptType'
                    />
                </div>
                <hr />
                <div v-if="viewState === 'ALL_ELEMENTS'">
                    <ClientsForAppt
                        :eventType='selectedEventType'
                        :clientList='active_clients'
                        :clientScheduledNow='clientId'
                        :clientsToBeScheduled='clients'
                        :modalOpen='open'
                        @clientSelected='handleClientSelected'
                        @deleteClientBtnClick='(index) => handleDeleteClient(index)'
                        @addClientBtnClick='handleAddClient'
                    />
                    <CounselorsForAppt :clinicians='clinicians' :open='open' @counselorAdded='setClinicians' />
                    <ServicesForAppt
                        :serviceList='services'
                        @serviceAdded='setServices'
                    />
                </div>
                <hr /> -->

                <!--     O T H E R             -->
                <!-- <div v-if="viewState === 'BASE_ELEMENTS'">
                    <UsersForAppt :activeUsers='activeUsers' @setUsers='setUsers' />
                </div>

                <div v-if="['Group', 'Couple', 'Family'].includes(this.selectedEventType)">
                    <label for='noteSummary'>{{ selectedEventType }} Note</label>
                    <TextArea
                        type="text"
                        id="noteSummary"
                        name="noteSummary"
                        :rows=4
                        :cols=50
                        label=""
                        v-model="summaryNote"
                        :limit=4000
                    />
                </div>

                <div v-if='this.selectedEventType !== null' class='align-right'>
                    <button type='button' class='secondary cancel' @click="$emit('close')">Cancel</button>
                    <button type='submit' class='primary' :disabled="isCreateButtonDisabled">Create</button>
                </div> -->
            <!-- </div> -->

            <div class="row form-content pt-1 me-5">
                <div class="col-sm-9 mb-4">
                    <Title :eventType='selectedEventType' @setTitle='setTitle' titlePlaceholder="Add Appointment Title*" :modalOpen='open' />
                </div>
                <div class="col-sm-9 mb-4 no-display-block">
                    <label class="bottom-10" v-if="selectedEventType !== 'Other'">
                        <Toggle
                            id='isRequired'
                            class='toggle-switch ms-0'
                            v-model='apptIsRequired'
                            labelClass="switch"
                            spanClass="slider"
                        />
                        <span class="fs-6 fw-bolder">Mandatory to attend</span>
                    </label>
                </div>
                <div class="col-sm-9 mb-4">
                    <DateTime
                        :date='date'
                        :open='open'
                        @dateTimeSelected='setDateTime'
                        @rRule='changeRuleData'
                        :isRecurring='recurringAppt'
                    />
                </div>
                <div class="col-sm-9 mb-5 no-display-block">
                    <label class="bottom-10" v-if="selectedEventType !== 'Other'">
                        <Toggle
                            id='isMonorToggle'
                            class='toggle-switch ms-0'
                            v-model='recurringAppt'
                            labelClass="switch"
                            spanClass="slider"
                        />
                        <span class="fs-6 fw-bolder">Recurring Appointment</span>
                    </label>
                </div>
                <div class="col-sm-9 mb-5">
                    <Location
                        :rooms='rooms'
                        :modalOpen='open'
                        :typeofAppt='selectedEventType'
                        @selectedPlaceOfService='setPlaceOfService'
                        @setApptType='setApptType'
                    />
                </div>
                <div class="col-sm-9 mb-5" v-if="viewState === 'ALL_ELEMENTS'">
                    <ClientsForAppt
                        :eventType='selectedEventType'
                        :clientList='active_clients'
                        :clientScheduledNow='clientId'
                        :clientsToBeScheduled='clients'
                        :modalOpen='open'
                        @clientSelected='handleClientSelected'
                        @deleteClientBtnClick='(index) => handleDeleteClient(index)'
                        @addClientBtnClick='handleAddClient'
                    />
                </div>
                <div class="col-sm-9 mb-5" v-if="viewState === 'ALL_ELEMENTS'">
                    <CounselorsForAppt :clinicians='clinicians' :open='open' @counselorAdded='setClinicians' />
                </div>
                <div class="col-sm-9 mb-5" v-if="viewState === 'ALL_ELEMENTS'">
                    <ServicesForAppt
                        :serviceList='services'
                        @serviceAdded='setServices'
                    />
                </div>
                
                <!--     O T H E R     -->
                <div class="col-sm-9 mb-5" v-if="viewState === 'BASE_ELEMENTS'">
                    <UsersForAppt :activeUsers='activeUsers' @setUsers='setUsers' />
                </div>

                <div class="col-sm-9" v-if="['Group', 'Couple', 'Family'].includes(this.selectedEventType)">
                    <label for='noteSummary' class="fw-bolder">{{ selectedEventType }} Note</label>
                    <TextArea
                        type="text"
                        id="noteSummary"
                        name="noteSummary"
                        :rows=4
                        :cols=50
                        label=""
                        v-model="summaryNote"
                        :limit=4000
                    />
                </div>

                <div class="col-sm-9" v-if="this.selectedEventType !== null">
                    <button type="submit" class="primary btn-save-appt" :disabled="isCreateButtonDisabled">Save Appointment</button>
                </div>
            </div>
        </form>
    <!-- </NoButtonModal> -->
    <Loading v-if="showLoader" />
</div>

  
                 
</template>

<script>
    import Vue from 'vue';
    import { ComboBoxPlugin } from '@syncfusion/ej2-vue-dropdowns';
    import { DropDownListPlugin } from '@syncfusion/ej2-vue-dropdowns';
    import { RecurrenceEditorPlugin } from '@syncfusion/ej2-vue-schedule';
    import { DatePickerPlugin, TimePickerPlugin } from '@syncfusion/ej2-vue-calendars';
    import CurrencyInput from '@/components/general/inputs/CurrencyInput';
    import { generateId } from '@/util/genericUtilityFunctions';
    import Title from './CreateAppointment/Title.vue';
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import GrpApptWarning from '@/components/general/modals/GroupApptWarning';
    import dayjs from 'dayjs';
    import DateTime from './CreateAppointment/DateTime.vue';
    import Location from './CreateAppointment/LocationApptType';
    import ClientsForAppt from './CreateAppointment/ClientsForAppt';
    import CounselorsForAppt from './CreateAppointment/CounselorsForAppt';
    import UsersForAppt from './CreateAppointment/UsersForAppt';
    import ServicesForAppt from './CreateAppointment/ServicesForAppt';
    import Loading from '@/components/general/loading/loading.vue';

    Vue.use(RecurrenceEditorPlugin);
    Vue.use(TimePickerPlugin);
    Vue.use(DatePickerPlugin);
    Vue.use(DropDownListPlugin);
    Vue.use(ComboBoxPlugin);

    export default {
        name: 'CreateAppointment',
        props: {
            open: Boolean,
            date: Date,
            clinicians: {
                type: Array,
                required: true
            },
            clientList: {
                type: Array,
                required: true
            },
            active_clients: {
                type: Array,
                required: true
            },
            services: {
                type: Array,
                required: true
            },
            offices: {
                type: Array,
                required: true
            },
            rooms: {
                type: Array,
                required: true
            },
            clientId: {
                type: Number,
                required: false
            },
            apptType: {
                type: String,
                required: false
            },
            activeUsers: {
                type: Array,
                required: true
            }
        },
        components: {
            CounselorsForAppt,
            ClientsForAppt,
            DateTime,
            NoButtonModal,
            CurrencyInput,
            GrpApptWarning,
            Title,
            Location,
            ServicesForAppt,
            UsersForAppt,
            Loading
        },

        data() {
            return {
                title: '',
                eventType: ['Individual', 'Group', 'Couple', 'Family', 'Other'],
                selectedEventType: null,
                selectedRoom: null,
                selectedLocation: [null],
                placeOfService: null,
                startDate: new Date(),
                endDate: null,
                summaryNote: "",
                subject: null,
                selectedStaff: [{ id: null, name: null, host: false }],
                counselors: null,
                clients: [null],
                selectedServices: [{ id: null, cost: null, generateTypeId: generateId(), retail_cost: null }],
                minDate: new Date(),
                maxDate: new Date('5/25/2029 2:00 AM'),
                dateFormat: 'EEEE, MM/dd/yyyy',
                minTime: new Date('3/8/2199 7:00 AM'),
                maxTime: new Date('3/8/2199 9:00 PM'),
                timeStep: 15,
                grp_warning: false,
                isRepeat: false,
                telehealth: 1,
                recurringAppt: false,
                rRulePayload: {},
                apptIsRequired: true,
                users: null,
                isCreateButtonDisabled: false,
                showLoader: false
            };
        },
        computed: {
            viewState() {
                switch (this.selectedEventType || this.apptType) {
                    case 'Individual':
                    case 'Group':
                    case 'Family':
                    case 'Couple':
                        return 'ALL_ELEMENTS';
                    case 'Other':
                        return 'BASE_ELEMENTS';
                    default:
                        return 'NONE_SELECTED';
                }
            }
        },
        methods: {
            setTitle(title) {
                this.title = title;
            },
            setPlaceOfService(place){
                //set the place of service
                this.placeOfService = place

                // set the telehealth flag on appt
                switch(place){
                    default:
                    case '02':
                        this.telehealth = 1
                        this.selectedRoom =  this.getRoomId('Telehealth')
                        break;
                    case '11':
                        this.telehealth = 0
                        this.selectedRoom =  this.getRoomId('Office')
                        break;
                }
            },

            getRoomId(location){
                return this.rooms.find(room => room.room_name === location)?.id
            },
            setDateTime(dateTime) {
                this.startDate = dateTime[0].startDate;
                this.endDate = dateTime[0].endDate;
            },

            setApptType(type) {
                this.selectedEventType = type;

                if (this.selectedEventType === "Individual") {
                    if (this.clients.length > 1) {
                        const clientArray = [];
                        this.clients.forEach((client, index) => {
                            if (index !== 0) {
                                clientArray.push({ info: client, index: index });
                            }
                        });
                        clientArray.forEach((client) => {
                            this.clients.splice(client.index, 1);
                            this.$emit('clientDeleted', client);
                        });
                    }
                }
                this.$emit('setApptType', this.selectedEventType)

                if(this.selectedEventType === 'Other'){
                    this.recurringAppt = false
                    this.rRulePayload = {}
                }
                  // Scroll down
                  window.scrollTo({
                        bottom: 0,
                        behavior: "smooth"
                    });
            },


            async get_active_clients() {
                try {

                    let clientListIndex = this.active_clients.findIndex((clientListItem) => this.clientId === clientListItem.id);
                    if(clientListIndex > -1){
                        let copy = this.active_clients[clientListIndex]
                        copy.$isDisabled = true
                        this.$set(this.active_clients, clientListIndex, copy)
                        this.clients = copy;
                    }


                } catch (err) {
                    //this.$toasted.error('Could not retrieve the list of clients');
                }
            },

            handleAddClient(){
                this.clients.push({})
            },
            handleDeleteClient(client){
                this.clients.splice(client.index, 1)
                this.$emit('clientDeleted', client)
            },
            handleClientSelected(client){
                if (this.selectedEventType === 'Individual') {
                    this.clients = client.info;
                    this.$emit('clientSelected', client)
                    return;
                }

                this.$emit('clientSelected', this.clients)
            },
            setClinicians(counselors) {
                this.selectedStaff = counselors;
            },
            setUsers(users) {
                this.users = users;
            },
            setServices(services) {
                this.selectedServices = services;
            },
            verifyFields() {
                if (!this.title) {
                    this.$toasted.error(`Please add appointment title`);
                    return false;
                }

                if (!this.startDate || this.startDate === 'Invalid Date') {
                    this.$toasted.error(`Start time is required`);
                    return false;
                }

                if (!this.endDate || this.endDate === 'Invalid Date') {
                    this.$toasted.error(`End time is required`);
                    return false;
                }

                if (new Date(this.startDate) === new Date(this.endDate)) {
                    this.$toasted.error(`End time should be greater than start time`);
                    return false;
                }
               
                if(new Date(this.startDate) > new Date(this.endDate)) {
                    this.$toasted.error(`End time should be greater than start time`);
                    return false;
                }

                if (!this.placeOfService) {
                    this.$toasted.error(`Please select place of service`);
                    return false;
                }

                if (this.selectedEventType === 'Other') {
                    if (!this.users?.meetingHost?.id) {
                        this.$toasted.error(`At least one meeting host needs to be selected for this event`);
                        return false;
                    }

                    if (!this.users.users[0].id) {
                        this.$toasted.error(`At least one user needs to be selected for this event`);
                        return false;
                    }

                    return true;
                }

                if (this.selectedEventType === 'Individual') {
                    if (this.clients == null || !this.clients.id) {
                        this.$toasted.error(`A member needs to be selected for this appointment`);
                        return false;
                    }
                } else {
                    if (!(this.clients.length && this.clients.some(obj => obj?.id))) {
                        this.$toasted.error(`At least one member needs to be selected for this appointment`);
                        return false;
                    } else {
                        this.clients = this.clients.filter(obj => obj.id);
                    }
                }

                if (!(this.selectedStaff.length && this.selectedStaff.some(obj => obj.id))) {
                    this.$toasted.error(`At least one counselor needs to be selected for this appointment`);
                    return false;
                } else {
                    this.selectedStaff = this.selectedStaff.filter(obj => obj.id);
                }

                if (!(this.selectedServices.length && this.selectedServices.some(obj => obj.id))) {
                    this.$toasted.error(`At least one service needs to be selected for this appointment`);
                    return false;
                } else {
                    this.selectedServices = this.selectedServices.filter(obj => obj.id);
                }

                return true;
            },

            closeGrpWarning() {
                this.grp_warning = false;
                this.newAppointment();
            },

            changeRuleData(e) {
                this.rRulePayload = {};

                Object.keys(e).forEach((key) => {
                    this.rRulePayload[key] = e[key];
                });
            },

            async newAppointment() {
                try {
                    if (!this.verifyFields()) {
                        return;
                    }

                    this.showLoader = true;
                    if( typeof this.startDate === "object" ) {
                        this.startDate = dayjs(this.startDate).format();
                    }

                    if( typeof this.endDate === "object" ) {
                        this.endDate = dayjs(this.endDate).format();
                    }

                    const body = {
                        clients: Array.isArray(this.clients) ? this.clients : [this.clients],
                        counselors: this.counselors,
                        endTime: this.endDate,
                        isRequired: this.apptIsRequired ? 1 : 0,
                        notes: this.summaryNote,
                        room: this.selectedRoom,
                        placeOfService: this.placeOfService,
                        services: this.selectedServices,
                        startTime: this.startDate,
                        subject: this.title,
                        type: this.selectedEventType,
                        telehealth: this.telehealth,
                        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        utcOffset: new Date().getTimezoneOffset(),
                    };

                    if (this.recurringAppt) {
                        let err = 0;
                        Object.keys(this.rRulePayload).forEach((key) => {
                            if (!this.rRulePayload[key] || !Number.isInteger(this.rRulePayload[key])) {
                                err++;
                            }
                        });
                        
                        if (err > 0) {
                            this.$toasted.error(`Recurring fields are not valid`);
                            return;
                        }

                        body.recurrenceRule = this.rRulePayload;
                    }

                    this.isCreateButtonDisabled = true;
                    await this.$api.post('/appts/create-appointment', body);
                    this.$emit('close');
                    this.$emit('savedAppt');
                    this.$toasted.success(`Successfully created appointment`);
                    this.isCreateButtonDisabled = false;
                } catch (err) {
                    this.$toasted.error(`Appointment creation unsuccessful`);
                } finally {
                    this.showLoader = false;
                }
            },

            async create() {
                switch (this.selectedEventType) {
                    default: {
                        this.newAppointment();
                        break;
                    }
                    case 'Group':
                    case 'Couple':
                    case 'Family':
                        if (this.verifyFields()) {
                            if (this.clients.length === 1) {
                                this.grp_warning = true;
                            } else {
                                this.newAppointment();
                            }
                        }
                        break;
                    case 'Other': {
                        try {
                            if (!this.verifyFields()) {
                                return;
                            }

                            if( typeof this.endDate === "object" ) {
                                this.endDate = dayjs(this.endDate).format();
                            }
                            this.showLoader = true;
                            this.isCreateButtonDisabled = true;
                            const res = await this.$api.post('/events/create-event', {
                                Subject: this.title,
                                Location: this.selectedRoom,
                                StartTime: this.startDate,
                                EndTime: this.endDate,
                                type: this.selectedEventType,
                                place_of_service_code: this.placeOfService,
                                users: this.users
                            });

                            this.isCreateButtonDisabled = false;
                            if (res.status >= 200 && res.status < 300) {
                                this.$emit('close');
                                this.$emit('savedEvent');
                                break
                            } else {
                                this.$toasted.error(`Appointment creation unsuccessful`);
                                break
                            }
                        } catch (err) {
                            this.$toasted.error(`Event creation unsuccessful`);
                        } finally {
                            this.showLoader = false;
                        }
                        break;
                    }
                }
            },

            characterCounter(val, limit) {
                return ((val) ? val.length : 0) + " / " + limit + " character(s) found";
            },

        },
        watch: {
            open() {
                //if the modal is closed
                if (!this.open) {
                    this.clients = [null];
                    this.recurringAppt = false;
                    this.selectedEventType = null;
                    this.selectedStaff = null;
                    this.selectedRoom = null;
                    this.startDate = null;
                    this.endDate = null;
                    this.selectedServices = [{ id: null, cost: null, generateTypeId: generateId() }];
                    this.selectedStaff = [{ id: null, name: null, host: false }];
                    this.subject = null;
                    this.summaryNote = "";
                    this.$emit('close');
                }

                this.clientList.forEach((client) => (client.$isDisabled = false));
                this.active_clients.forEach((client) => (client.$isDisabled = false));
                // this.clinicians.forEach((clinician) => (clinician.$isDisabled = false));
                // this.services.forEach((service) => (service.$isDisabled = false));
            },

            date: {
                //handles date changes
                handler: function(newVal) {
                    this.startDate = newVal;
                    let hourDifference = this.startDate.getHours() + 1;
                    this.endDate = new Date(new Date(this.startDate));
                    this.endDate.setHours(hourDifference);
                },
                deep: true,
                immediate: true
            },

            // handles the change of the selected appt type
            selectedEventType() {
                if (this.selectedEventType !== 'Individual') {
                    this.$emit('getAllServices');
                    // if this.clients is an [array], then leave it as one to show "add client" button
                    this.clients = Array.isArray(this.clients) ? this.clients : [this.clients];
                } else {
                    // if this.clients is an [array], then take the first index and destructure
                    this.clients = Array.isArray(this.clients) ? this.clients[0] : this.clients;
                }
            },

             selectedStaff() {
                this.counselors = [...this.selectedStaff];
            },

            clients() {
                if (this.clients && this.selectedEventType === 'Individual') {
                    let clientId = this.clients;
                    this.$emit('getClientServiceFees', clientId);
                } else if (this.clients == null) {
                    this.$emit('getAllServices');
                }
            },

            active_clients(){
                if(this.clientScheduledNow){
                    this.clients = this.active_clients.find((client) =>  client.id === this.clientScheduledNow)
                }
            },


            '$route.params.apptType': {
                handler: function(apptType) {
                    if (apptType) {
                        this.selectedEventType = this.$route.params.apptType;
                    }
                    if (this.open) {
                        let hourDifference = this.startDate.getHours() + 1;
                        this.endDate = new Date(new Date(this.startDate));
                        this.endDate.setHours(hourDifference);
                    }
                },
                deep: true,
                immediate: true
            },

            '$route.params.clientId': {
                 handler: function(clientId) {
                    if (clientId) {
                        let list = this.$store.getters['scheduler/clientList']
                        let client = list.find((client) =>  client.id === clientId)
                        if(client){
                            this.clients = client;
                            this.$emit('clientSelected', client)
                        }
                    }
                },
                deep: true,
                immediate: true
            }
        }

    };
</script>

<style scoped>
    form {
        border-radius: 20px;
        padding: 23px 20px 20px 20px;
        height: 54rem;
    }
    .form-content{
        height: 54rem;
        overflow: auto;
    }
    .btn-save-appt {
        font-size: 1.2rem;
        font-weight: 600;
        padding: 1rem 2rem;
    } 

    .no-display-block label {
        display: unset !important;
    }
</style>
